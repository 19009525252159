<ul id="al-sidebar-list" class="al-sidebar-list al-scrollable-list" ngaBaSlimScroll
    (mouseleave)="hoverElemTop=outOfArea">
  <!--<nga-ba-menu-item
    [menuItem]="item"
    [subscriptionInactive]="subscriptionInactive"
    (itemHover)="hoverItem($event)"
    (toggleSubMenu)="toggleSubMenu($event)"
    *ngFor="let item of menuItems">

  </nga-ba-menu-item>-->

  <rip-sidebar-workspace
   [sidebarCollapsed]="sidebarCollapsed"
   [style.margin-bottom.rem]="1"
   *ngIf="!inWealthFluent"
  ></rip-sidebar-workspace>

  <rip-pages-menu
    (itemHover)="hoverItem($event)"
    [sidebarCollapsed]="sidebarCollapsed"
    *ngIf="!subscriptionInactive">

  </rip-pages-menu>

  <!-- Commenting for now until we get the market data settled -->
  <!--<rip-market-dashboard [menuHeight]="menuHeight"
                        [sidebarCollapsed]="isMenuCollapsed">
  </rip-market-dashboard>-->
<!--  <rip-account-management-menu-->
<!--    [sidebarCollapsed]="sidebarCollapsed"-->
<!--    (itemHover)="hoverItem($event)"-->
<!--    *ngIf="!subscriptionInactive && !inWealthFluent">-->
<!--  </rip-account-management-menu>-->

  <rip-preferences
    [sidebarCollapsed]="sidebarCollapsed"
    (itemHover)="hoverItem($event)"
    *ngIf="!subscriptionInactive">

  </rip-preferences>

</ul>

<div>
  <rip-user-menu (itemHover)="hoverItem($event)" [sidebarCollapsed]="sidebarCollapsed" *ngIf="!inWealthFluent"></rip-user-menu>
<!--  <rip-help-menu [sidebarCollapsed]="sidebarCollapsed" *ngIf="!inWealthFluent"></rip-help-menu>-->
</div>

<div class="sidebar-hover-elem" [ngStyle]="{top: hoverElemTop + 'px', height: hoverElemHeight + 'px'}"
     [ngClass]="{'show-hover-elem': showHoverElem }">
</div>
