import { inject, Injectable } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { CrossAppMessage, CrossAppMessageDataType, CrossAppMessageType, DialogName, InvestorGoal } from '@ripsawllc/ripsaw-analyzer';
import { Auth } from '../../../auth.service';
import { Router } from '@angular/router';
import { Logger } from '../../../utils/logger.service';
import { SettingsComponent } from '../../../pages/modals/settings';
import { MatDialog } from '@angular/material/dialog';
import { AppStoreService } from '../../../store';
import { UserGoal, WorkspaceEvent } from '../../../utils/dataInterfaces';
import { AccountManager } from '../../../utils/accountManager';
import { GoalsState } from '../../../utils/goals.state';
import moment from 'moment';
import { Moment } from 'moment/moment';
import { GlobalState } from '../../../global.state';
import { EVENT_NAMES, PAGE_NAMES } from '../../../utils/enums';
import { SubscriptionDetailsUtil } from '../../../utils/subscriptionDetails.util';
import { cloneDeep } from 'lodash-es';
import { BenchmarkState } from '../../../utils/benchmark.state';
import { TransactionsState } from '../../../utils/transactions.state';


@Injectable( {
  providedIn: 'root',
} )
export class WealthFluentMessageService {

  private readonly onDestroy: Subject<void> = new Subject<void>();
  readonly authenticatedViaWF: Subject<boolean> = new Subject<boolean>();
  readonly mobileNavigation: Subject<string> = new Subject<string>();

  messages: CrossAppMessage[] = [];

  private _auth: Auth = inject( Auth );
  private _router: Router = inject( Router );
  dialog: MatDialog = inject( MatDialog );
  alreadyAuthenticated: boolean;
  private appStoreService: AppStoreService = inject( AppStoreService );
  private _accountManager: AccountManager = inject( AccountManager );
  private _goalsState: GoalsState = inject( GoalsState );
  private _state: GlobalState = inject( GlobalState );
  private subscriptionDetailsUtil: SubscriptionDetailsUtil = inject( SubscriptionDetailsUtil );
  private benchmarkState: BenchmarkState = inject( BenchmarkState );
  private transactionsState: TransactionsState = inject( TransactionsState );

  hasNavigatedSinceLastToldToByWF: boolean;

  init(): void {

    this._state.subscribe( EVENT_NAMES.USER_BENCHMARK_SAVED, () => {
      this.tellWFBenchmarkUpdated();
    }, 'wealthFluentMessageService' );

    this._state.subscribe( EVENT_NAMES.POSITION_OVERRIDDEN, () => {
      const cam = {
        type: CrossAppMessageType.data,
        data: {
          dataType: CrossAppMessageDataType.OVERRIDE,
          overrides: {
            globalOverrides: this._state.globalVars.globalOverrides,
            accountMapping: this._state.globalVars.accountMapping,
          },
        },
        message: 'position overridden',
      };
      this.messageWealthFluent( cam );
    } );

    this._auth.loggedOutSignal.pipe( takeUntil( this.onDestroy ) )
      .subscribe( () => {
        this.requestNewTokenFromWF();
      } );

    window.addEventListener( 'message', ( m: MessageEvent<any> ) => {
      if ( m.origin === environment.wealthFluentOrigin ) {
        this.handleMessageFromWealthFluent( m.data );
      }
    } );

    if ( environment.env !== 'prod' ) {
      window[ 'wealthFluentMessageService' ] = this;
    }

    this.appStoreService.workspaceLoadedNotFromWFMessage.pipe( takeUntil( this.onDestroy ) )
      .subscribe( {
        next: ( workspaceEvent: WorkspaceEvent ) => {
          this.messageWealthFluent( {
            type: CrossAppMessageType.workspaceLoaded,
            data: {
              workspaceEvent,
            },
            message: `Load this workspace`,
          } );
        },
      } );

    this.appStoreService.workspaceCreated.pipe( takeUntil( this.onDestroy ) )
      .subscribe( {
        next: ( workspaceEvent: WorkspaceEvent ) => {
          this.messageWealthFluent( {
            type: CrossAppMessageType.workspaceCreated,
            data: {
              workspaceEvent,
            },
            message: `This workspace was added`,
          } );
        },
      } );

    this.appStoreService.workspaceUpdated.pipe( takeUntil( this.onDestroy ) )
      .subscribe( {
        next: ( workspaceEvent: WorkspaceEvent ) => {
          this.messageWealthFluent( {
            type: CrossAppMessageType.workspaceUpdated,
            data: {
              workspaceEvent,
            },
            message: `This workspace was updated`,
          } );
        },
      } );

    this.appStoreService.workspaceRemoved.pipe( takeUntil( this.onDestroy ) )
      .subscribe( {
        next: ( workspaceEvent: WorkspaceEvent ) => {
          this.messageWealthFluent( {
            type: CrossAppMessageType.workspaceRemoved,
            data: {
              workspaceEvent,
            },
            message: `This workspace was removed`,
          } );
        },
      } );

    this._accountManager.needWFToRefreshPrices.pipe( takeUntil( this.onDestroy ) )
      .subscribe( {
        next: () => {
          this.messageWealthFluent( {
            type: CrossAppMessageType.priceRefreshRequest,
            data: {},
            message: 'Price refresh requested',
          } );
        },
      } );

    this.subscriptionDetailsUtil.needNewToken.pipe( takeUntil( this.onDestroy ) )
      .subscribe( {
        next: val => {
          if ( val ) {
            this.requestNewTokenFromWF();
          }
        },
      } );

    this._auth.needNewTokenSignal.pipe( takeUntil( this.onDestroy ) )
      .subscribe( {
        next: () => {
          this.requestNewTokenFromWF();
        },
      } );
  }

  messageWealthFluent( cam: CrossAppMessage ): void {
    const wfWindow: Window = window.parent ?? window.top;
    if ( wfWindow?.postMessage ) {
      if ( !cam.data ) {
        cam.data = {};
      }
      cam.data.destination = 'wf';
      cam.data.source = 'rip';
      // WealthFluent available
      wfWindow.postMessage( cam, environment.wealthFluentOrigin );
    } else {
      // still waiting for WealthFluent's message service for some reason
      this.messages.push( cam );
    }
  }

  handleMessageFromWealthFluent( cam: CrossAppMessage ): void {
    if ( cam.data.QUEUE_NUMBER ) {
      Logger.log( `QUEUE_NUMBER as received: ${ cam.data.QUEUE_NUMBER }` );
    }
    Logger.log( `cam.message: ${ JSON.stringify( cam.message ) }` );
    switch ( cam.type ) {
      case CrossAppMessageType.tokenPass:
        this._auth.setNewToken( cam.data.token );
        this.authenticatedViaWF.next( true );
        let path: string;
        if ( this._auth.authenticated() && !this.alreadyAuthenticated ) {
          this.alreadyAuthenticated = true;
          path = '/pages/accounts';

        }

        if ( cam.data.url ) {
          path = cam.data.url.split( '/' ).pop();
        }

        if ( path && !this.hasNavigatedSinceLastToldToByWF ) {
          this._router.navigateByUrl( this.translateWFPath( path ) )
            .catch( err => {
              console.error( err );
            } );
        }

        return;
      case CrossAppMessageType.navigate:
        Logger.log( 'Navigate Message Received: ' );
        Logger.log( cam );
        if ( cam.data?.type === 'mobile' ) {
          this.mobileNavigation.next( cam.data.path );
        } else {
          this._router.navigate( [ this.translateWFPath( cam.data?.path ) ],
            { queryParams: cam.data?.params } )
            .catch( err => {
              console.error( err );
            } );
        }
        this.hasNavigatedSinceLastToldToByWF = false;
        return;
      case CrossAppMessageType.logout:
        this.alreadyAuthenticated = false;
        this._auth.logout();
        return;
      case CrossAppMessageType.openDialog:
        this.openDialog( cam.data.dialogName );
        return;
      case CrossAppMessageType.workspaceLoaded:
        const event: WorkspaceEvent = cam.data.workspaceEvent;
        this.appStoreService.loadWorkspace( event.workspace, event.type, true );
        return;
      case CrossAppMessageType.data:
        this.handleDataMessage( cam.data.dataType, cam.data );
        return;
      case CrossAppMessageType.subscriptionChanged:
        location.reload();
        return;
      case CrossAppMessageType.refreshBenchmark:
        if ( cam.data?.benchmark?.id ) {
          this.benchmarkState.setUserBenchmark( cam.data.benchmark );
        }
        return;
      default:
        Logger.log( `Unrecognized Message Type. Message was ignored: ` );
        Logger.log( cam );
    }

  }

  requestNewTokenFromWF(): void {
    if ( !this._auth.authenticated() ) {
      this.alreadyAuthenticated = false;
    }
    this.messageWealthFluent( {
      type: CrossAppMessageType.tokenPass,
      message: 'Logged out for some reason, need a new token',
      data: {},
    } );
  }

  connectionRemoved( connection_id: string ): void {
    this.messageWealthFluent( {
      type: CrossAppMessageType.refreshAccounts,
      data: {
        connectionRemoved: connection_id,
      },
      message: 'A connection was removed',
    } );
  }

  openDialog( dialogName: DialogName ): void {
    Logger.log( `opening dialog: ${ dialogName }` );
    switch ( dialogName ) {
      case DialogName.SETTINGS:
        this.dialog.open( SettingsComponent, {
          // width: '55vw',
          height: '50vh',
          panelClass: 'settings-panel',
          hasBackdrop: false,
        } );
        return;
    }
  }

  tellWFBenchmarkUpdated() {
    this.messageWealthFluent( {
      type: CrossAppMessageType.refreshBenchmark,
      data: {
        benchmark: this.benchmarkState.benchmark,
      },
      message: 'Benchmark saved in ripsaw',
    } );
  }

  handleDataMessage( dataType: CrossAppMessageDataType, data: any ): void {
    switch ( dataType ) {
      case CrossAppMessageDataType.HOUSEHOLD_MEMBER:
        if ( data.new ) {
          this._goalsState.householdMembersState.handleNewMember( data.member );
        } else {
          this._goalsState.householdMembersState.handleUpdatedMember( data.member );
        }
        return;
      case CrossAppMessageDataType.GOAL:
        if ( data.new ) {
          this._goalsState.addNewGoalToList( data.goal );
        } else {
          if ( data.delete ) {
            this._goalsState.removeGoalFromList( data.goal_id );
          } else {
            this._goalsState.updateGoalInList( data );
          }
        }
        return;
      case CrossAppMessageDataType.ACCOUNTS:
        if ( this._state.globalVars.firstAccountPullComplete && ( this._state.globalVars.editing || this._state.globalVars.currentPageTitle === PAGE_NAMES.PLAN_BENCHMARK ) ) {
          console.log( `can't update accounts right now because they are in the middle of a revision or on the planning page.` );
        } else {
          Logger.log( `ACCOUNTS RECEIVED BY RIPSAW: ${ JSON.stringify( data ) }` );
          Object.assign( this._state.globalVars, data.globalVars );
          this._state.globalVars.firstAccountPullComplete = true;
          this._state.globalVars.loadingAccounts = false;
          this._state.globalVars.accountsCameFromWF = true;
          this._accountManager.allDataRetrieved.next( true );
          this._state.notifyDataChanged( EVENT_NAMES.ACCOUNT_MANAGER_REFRESH_COMPLETE );
          this._state.notifyDataChanged( EVENT_NAMES.PRICES_UPDATED );
        }
        return;
      case CrossAppMessageDataType.SUBSCRIPTION_INFO:
        Object.assign( this.subscriptionDetailsUtil, data.subInfo );
        this.subscriptionDetailsUtil.checkCurrentSubscriptionInfo();
        return;
      case CrossAppMessageDataType.BENCHMARK:
        if ( data.err !== undefined ) {
          this.benchmarkState.frontierError = data.err;
        }
        return;
      case CrossAppMessageDataType.TRANSACTIONS:
        Object.assign( this.transactionsState, data.transactionData );
        this.transactionsState.minDateReturned = moment( data.minDateReturned );
        this.transactionsState.maxDateReturned = moment( data.maxDateReturned );
        this.transactionsState.netBarChartData.labels = this.transactionsState.netBarChartData.labels.map( ( m: string ) => moment( m ) );
        this.transactionsState.loading = false;
        return;
      default:
        return;
    }
  }

  sendOpenEditGoalMessage( goal: InvestorGoal | UserGoal ): void {
    const g: any = cloneDeep( goal );
    if ( g.goal_date ) {
      g.goal_date = this.convertMomentToDateStringForPostMessage( g.goal_date );
    }
    if ( g.created_at ) {
      g.created_at = this.convertMomentToDateStringForPostMessage( g.created_at );
    }
    if ( g.updated_at ) {
      g.updated_at = this.convertMomentToDateStringForPostMessage( g.updated_at );
    }
    if ( g.withdrawal_dates?.length > 0 ) {
      for ( let d of g.withdrawal_dates ) {
        if ( d.amount ) {
          d.date = this.convertMomentToDateStringForPostMessage( d.date );
        } else {
          // older, less common way to store the dates was an array of just Date objects
          d = this.convertMomentToDateStringForPostMessage( d );
        }
      }
    }
    this.messageWealthFluent( {
      type: CrossAppMessageType.openDialog,
      message: 'Open Edit Goal Dialog',
      data: {
        dialogName: DialogName.EDIT_GOAL,
        goal: g,
      },
    } );
  }

  convertMomentToDateStringForPostMessage( date: Moment | Date | string ): string {
    return moment( date ).format( 'YYYY-MM-DD' );
  }

  translateWFPath( path: string ): string {
    switch ( path ) {
      case 'planning':
        return '/pages/benchmark';
      case 'markets':
        return '/pages/market-information-dashboard';
      case 'transactions':
        return '/pages/transactions';
      case 'holdings':
        return '/pages/holdings';
      case 'portfolio':
        return '/pages/accounts';
      default:
        return path;
    }
  }

}